// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

/**
 * ----------------------------------------------------------------------
 * Data structures for describing a table row batch (a collection of
 * equal-length Arrow arrays)
 * Metadata about a field at some level of a nested type tree (but not
 * its children).
 *
 * For example, a List<Int16> with values `[[1, 2, 3], null, [4], [5, 6], null]`
 * would have {length: 5, null_count: 2} for its List node, and {length: 6,
 * null_count: 0} for its Int16 node, as separate FieldNode structs
 */
export class FieldNode {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
__init(i:number, bb:flatbuffers.ByteBuffer):FieldNode {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

/**
 * The number of value slots in the Arrow array at this level of a nested
 * tree
 */
length():flatbuffers.Long {
  return this.bb!.readInt64(this.bb_pos);
}

/**
 * The number of observed nulls. Fields with null_count == 0 may choose not
 * to write their physical validity bitmap out as a materialized buffer,
 * instead setting the length of the bitmap buffer to 0.
 */
nullCount():flatbuffers.Long {
  return this.bb!.readInt64(this.bb_pos + 8);
}

static sizeOf():number {
  return 16;
}

static createFieldNode(builder:flatbuffers.Builder, length: flatbuffers.Long, null_count: flatbuffers.Long):flatbuffers.Offset {
  builder.prep(8, 16);
  builder.writeInt64(null_count);
  builder.writeInt64(length);
  return builder.offset();
}

}
