// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

/**
 * Opaque binary data
 */
export class Binary {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
__init(i:number, bb:flatbuffers.ByteBuffer):Binary {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBinary(bb:flatbuffers.ByteBuffer, obj?:Binary):Binary {
  return (obj || new Binary()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBinary(bb:flatbuffers.ByteBuffer, obj?:Binary):Binary {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Binary()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static startBinary(builder:flatbuffers.Builder) {
  builder.startObject(0);
}

static endBinary(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBinary(builder:flatbuffers.Builder):flatbuffers.Offset {
  Binary.startBinary(builder);
  return Binary.endBinary(builder);
}
}
