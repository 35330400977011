// automatically generated by the FlatBuffers compiler, do not modify

/**
 * ----------------------------------------------------------------------
 * Endianness of the platform producing the data
 */
export enum Endianness{
  Little = 0,
  Big = 1
}

